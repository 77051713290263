<template>
  <span>
    <v-row align="center" justify="center" class="my-3">
      <login-with-email-dialog />
      <link
        type="text/css"
        rel="stylesheet"
        href="https://www.gstatic.com/firebasejs/ui/4.6.1/firebase-ui-auth.css"
      />
    </v-row>
    <!-- firebase login form -->

    <div id="login">
      <div id="firebaseui-auth-container"></div>
    </div>
  </span>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import CustomButton from "@/components/CustomButton.vue";
import LoginWithEmailDialog from "@/components/LoginWithEmailDialog.vue";

export default {
  components: { CustomButton, LoginWithEmailDialog },
  name: "auth",
  async mounted() {
    try {
      var uiConfig = {
        signInSuccessUrl: "/",
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            if (window.opener) {
              // The widget has been opened in a popup, so close the window
              // and return false to not redirect the opener.
              window.close();
              return false;
            } else {
              // The widget has been used in redirect mode, so we redirect to the signInSuccessUrl.
              return true;
            }
          },
        },
        signInOptions: [
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,

            customParameters: {
              // Forces account selection even when one account
              // is available.
              prompt: "select_account",
            },
          },
        ],
      };

      // Initialize the FirebaseUI Widget using Firebase.
      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth());
      }
      // The start method will wait until the DOM is loaded to include the FirebaseUI sign-in widget
      // within the element corresponding to the selector specified.

      ui.start("#firebaseui-auth-container", uiConfig);
    } catch (error) {
      alert(error);
    }
  },
};
</script>
