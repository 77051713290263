<template>
  <v-dialog v-model="dialogOpen" max-width="400px" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="error" v-on="on" class="signInBtn">
        <custom-button type="iconBtn" icon="mail" color="white" />
        <span class="pl-3">Sign in with email</span>
      </v-btn>
    </template>
    <v-card>
      <v-row align="center" justify="center" v-if="isLoading">
        <v-progress-circular
          :size="90"
          :width="7"
          color="success"
          indeterminate
          class="my-5"
        ></v-progress-circular>
      </v-row>

      <template v-else>
        <v-card-title>
          <v-row>
            <v-spacer />
            <custom-button
              type="textBtn"
              :text="$t('btn.close')"
              icon="close"
              @click="dialogOpen = false"
              color="error"
            />
          </v-row>
        </v-card-title>
        <v-card-title>
          <v-row align="center" justify="center">
            {{ $t("global.login-in-app") }}
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                outlined
                :label="$t('global.email')"
                prepend-icon="person"
                class="textInput"
                clearable
                :error="errorText != null"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                outlined
                :label="$t('global.password')"
                prepend-icon="lock_open"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                class="textInput"
                :error-messages="errorText"
              />
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <custom-button
                color="success"
                icon="login"
                :text="$t('global.login-in-app')"
                @click="onLoginPress"
                :disabled="isLoginDisabled"
              />
            </v-col>
          </v-container>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty, isEqual, isNil } from "lodash";
import firebase from "firebase/app";
import "firebase/auth";
import CustomButton from "./CustomButton.vue";
import router from "@/router";
export default {
  components: { CustomButton },
  data() {
    return {
      dialogOpen: false,
      showPassword: false,
      password: "",
      email: "",
      errorText: null,
      isLoading: false,
    };
  },
  computed: {
    isLoginDisabled() {
      return (
        this.password.length < 3 ||
        isNil(this.email) ||
        !this.email.includes("@senior-coding.com")
      );
    },
  },
  methods: {
    async onLoginPress() {
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {
          this.dialogOpen = false;
          this.errorText = null;
          router.replace("/").then((data) => {
            window.location.reload();
          });
        })
        .catch((error) => {
          console.log(error.code);
          this.errorText = this.$t("global.wrong-email-or-password");
        });
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
  },
};
</script>

<style>
.signInBtn {
  margin-left: 22px;
  max-width: 220px !important;
  min-height: 40px;
  width: 100%;
}
.textInput {
  height: 60px;
}
</style>
